import React, { useEffect, useRef, useState } from "react";
import { recordObject } from "../libs/record";
import axios from 'axios';
import Moment from 'moment';
import Styles from "./RepresentativesReport.module.css";

import { DateRangePicker } from "rsuite";
let startRange = new Date();
let endRange = new Date();

function RepresentativesReport() {
  const [records, setRecords] = useState([]);  
  const [reportData, setReportData] = useState([])
  const [reportLoading, setReportLoading] = useState(false);

  const chartColors = {
  red: "#dc3545",
  orange: "#fd7e14",
  yellow: "#ffc107",
  green: "#28a745",
  darkGreen: "#28a700",
  blue: "#007bff",
  purple: "#6f42c1",
  grey: "#6c757d",
  aqua: "#00FFFF",
  lightGreen: "#14e684",
};

  const dateRangeRef = useRef(null);
 
  const handleFilterDate = (e) => {
    setReportLoading(true)
    console.log(e);
    if (!e) return;
    let months = [
      "",
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let pad = (n) => ("0" + n).slice(-2);
    let p = String(e[0]).split(" ");
    let b = String(e[1]).split(" ");
  
    const startDate = new Date(`${p[3]}-${pad(months.indexOf(p[1]))}-${p[2]}`);
    const endDate = new Date(`${b[3]}-${pad(months.indexOf(b[1]))}-${b[2]}`);
    const startDateTime = new Date(
      `${p[3]}-${pad(months.indexOf(p[1]))}-${p[2]}`
    )?.getTime();
    const endDateTime = new Date(
      `${b[3]}-${pad(months.indexOf(b[1]))}-${b[2]}`
    )?.getTime();
        
   
     startRange = Moment(startDate).format("Y-M-D");
     endRange = Moment(endDate).format("Y-M-D");    
    const config = {
        method: 'post',
        url: 'https://localhost:7110/api/Profile/GetRepresentativesReport?name=,,,&dt='+startRange+'%20'+endRange,
        headers: { 
            "Content-Type": "application/json",
        }
      };
      
      axios(config)
      .then(function (response) {
        if(response.status === 200){
          setReportData(response.data.response)
          setReportLoading(false)
        }else{
          setReportLoading(false)
        }
      }).catch(function (error) {
        setReportLoading(false)
        console.log(error);
      });  




    setRecords(() =>
      recordObject.record.filter((rec) => {
        const creationDate = new Date(rec?.creation?.split("T")[0]);
        const creationDateTime = new Date(
          rec?.creation?.split("T")[0]
        ).getTime();
        // console.log(
        //   creationDate,
        //   "creationDate",
        //   creationDateTime,
        //   "creationDateTime"
        // );
        
        // console.log(
        //   creationDateTime >= startDateTime && creationDate <= endDateTime
        // );
        return (
          creationDateTime >= startDateTime && creationDateTime <= endDateTime
        );
      })
    );
  };

  function GetRepresentativesReport(){
       setReportLoading(true)
    const startRange = Moment().format("Y-M-D");
    const endRange = Moment().format("Y-M-D");
      const config = {
        method: 'post',
        url: 'https://localhost:7110/api/Profile/GetRepresentativesReport?name=,,,&dt='+startRange+'%20'+endRange,
        headers: { 
            "Content-Type": "application/json",
        }
      };
      
    
    axios(config)
    .then(function (response) {
      if(response.status === 200){
        setReportLoading(false)
        setReportData(response.data.response)
      }else{
        setReportLoading(false)
      }
    })
    .catch(function (error) {
      console.log(error);
      setReportLoading(false)
    });


}

  useEffect(() => {
    GetRepresentativesReport();
  }, []);

  const currentdateTime = Moment();
  
  return (
    <div className="col-lg-12">
      <div className="main-card mb-3 card">
        <div className="card-body">
          <div>
            <div className="divider"></div>

            <div className="analytics-top-bar row justify-content-between align-items-center mb-2">
        <div className="col-auto">
          <div className="d-flex">
            <div className="col-auto">
                  <label htmlFor="staticSearch" className="visually-hidden">
                    Date
                  </label>
                  {/* <input type="text" className="form-control" id="daterange" name="daterange" placeholder="From - To (Date Range)" />
                  <input type="hidden" id="hideDates" /> */}
                  <DateRangePicker
                    appearance="default"
                    format="yyyy-MM-dd hh:mm aa"
                    onChange={handleFilterDate}
                    ref={dateRangeRef}
                    defaultValue={[new Date(currentdateTime), new Date(currentdateTime)]}
                  />
                </div>
          </div>
          
        </div>
      </div>


          </div>
        </div>
      </div>
      
       <div className="card mt-5">
      <div className="card-header">
        <h2 className="card-title text-center">Representatives Report</h2>
      </div>
      <div className="card-body">
        <div className={Styles.tableResponsive}>
          <table className="table Profiles table-hover table-striped table-bordered dataTable dtr-inline"
                cellSpacing="0"
                width="100%">
          <thead>
                  <tr style={{ height: "120px" }}>
                    <th style={{ width: "300px" }}>
                      <span>Reference</span>
                    </th>
                    <th style={{ width: "100px" }}>
                      <span>Extension</span>
                    </th>
                    <th className="vertical">
                      <span>Total calls</span>
                    </th>
                    <th className="vertical">
                      <span>Call duration (average)</span>
                    </th>
                    <th className="vertical">
                      <span>Good Outcome Number of Calls</span>
                    </th>
                    <th className="vertical">
                      <span>Bad Outcome Number of Calls</span>
                    </th>
                    <th className="vertical">
                      <span>Risk of abandonment</span>
                    </th>
                    <th  className="vertical"> 
                    <span> Need training </span></th>
                    <th  className="vertical"><span> Ability to Neutralize Distress </span></th>
                    <th  className="vertical"><span> Level of Involvement </span></th>
                    <th  className="vertical"><span> Average tone priority </span> </th>
                  </tr>
                </thead>
            {!reportLoading ? (
              <tbody>
                {reportData.length > 0 ? (
                  reportData.map((item, index) => (
                    <tr key={index}>
                      <td>{item.referance}</td>
                      <td>{item.extension}</td>
                      <td>{item.totalCalls}</td>
                      <td>{item.averageCallDuration.toFixed(2)}</td>
                      <td>{item.goodOutcome}</td>
                      <td>{item.badOutcome}</td>
                      <td className={Styles.green}>{item.riskOfAbandonment.toFixed(2)}</td>
                      <td className={Styles.green}>{item.needtraining.toFixed(2)}</td>
                      <td className={Styles.green}>{item.abilityToNeutralizeDistress.toFixed(2)}</td>
                      <td className={Styles.red}>{item.levelOfInvolvement.toFixed(2)}</td>
                      <td>{item.averageMaxToneCallPriority}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="11" className="text-center">
                      No data
                    </td>
                  </tr>
                )}
              </tbody>
            ) : (
              <tbody>
                <>
                  <tr>
                    <td
                      colSpan="11"
                      className="text-center"
                      style={{
                        height: "200px",
                      }}
                    >
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      ></div>
                    </td>
                  </tr>
                </>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
    </div>
  );
}

export default RepresentativesReport;
