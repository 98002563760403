export const recordObject = {
  "record": [
      {
          "id": 80,
          "reference": "00100013000_20230205_1236190538",
          "extension": "204",
          "creation": "2023-03-06T12:49:51.237397",
          "aggression": 3.846199999999996,
          "arousal": 80.7692,
          "atmosphere": 57.6923,
          "clStress": 2,
          "concentration": 61.5385,
          "discomfort": 0,
          "excitement": 88.4615,
          "hesitation": 38.4615,
          "imagination": 38.4615,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 69.2308,
          "uncertainty": 65.3846,
          "uneasy": 38.4615,
          "discomfortEnd": 3,
          "discomfortStart": 3,
          "tags": "#LOW-END-PRIORITY,#EMOTIONAL-CALL,#HIGH-EXCITEMENT,#INTEREST,#HIGH-ENERGY",
          "newTagsString": "",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 81,
          "reference": "00100035000_20230205_1238250750",
          "extension": "226",
          "creation": "2023-03-06T12:49:51.237397",
          "aggression": 4.3478000000000065,
          "arousal": 43.4783,
          "atmosphere": 30.434799999999996,
          "clStress": 1,
          "concentration": 56.5217,
          "discomfort": 0,
          "excitement": 69.5652,
          "hesitation": 43.4783,
          "imagination": 47.8261,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 82.6087,
          "uncertainty": 86.9565,
          "uneasy": 34.7826,
          "discomfortEnd": 4,
          "discomfortStart": 3,
          "tags": "#LOW-END-PRIORITY,#HIGH-UNCERTAINTY,#SOME-INTEREST",
          "newTagsString": "#HIGH-UNCERTAINTY",
          "startCall": "2023-03-06T12:49:51.237397",
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 82,
          "reference": "00100021000_20230205_1248450455",
          "extension": "212",
          "creation": "2023-03-06T12:49:51.237397",
          "aggression": 3.4483000000000033,
          "arousal": 41.3793,
          "atmosphere": 27.586200000000005,
          "clStress": 2,
          "concentration": 44.8276,
          "discomfort": 0,
          "excitement": 93.10345,
          "hesitation": 41.3793,
          "imagination": 72.4138,
          "joy": 3.4483000000000033,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 86.2069,
          "uncertainty": 89.65520000000001,
          "uneasy": 51.7241,
          "discomfortEnd": 5,
          "discomfortStart": 4,
          "tags": "#LOW-END-PRIORITY,#HIGH-EXCITEMENT,#SOME-INTEREST,#HIGH-UNEASY",
          "newTagsString": "",
          "startCall": "2023-03-06T12:49:51.237397",
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 79,
          "reference": "00100021000_20230205_1150080556",
          "extension": "212",
          "creation": "2023-02-05T12:30:34.6574124",
          "aggression": 10.4666,
          "arousal": 32.5347,
          "atmosphere": 1.5131999999999977,
          "clStress": 2,
          "concentration": 43.3796,
          "discomfort": 0,
          "excitement": 88.1463,
          "hesitation": 48.9281,
          "imagination": 56.7465,
          "joy": 1.5131999999999977,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 70.61789999999999,
          "uncertainty": 69.6091,
          "uneasy": 55.7377,
          "discomfortEnd": 4,
          "discomfortStart": 4,
          "tags": "#HIGH-PRIORITY,#LOW-END-PRIORITY,#AGGRESSION-END,#SOME-AGGRESSION,#SOME-INTEREST",
          "newTagsString": "",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 78,
          "reference": "00100035000_20230205_1229230152",
          "extension": "226",
          "creation": "2023-02-05T12:30:00.6493957",
          "aggression": 0,
          "arousal": 61.1111,
          "atmosphere": 0,
          "clStress": 1,
          "concentration": 44.4444,
          "discomfort": 0,
          "excitement": 88.8889,
          "hesitation": 44.4444,
          "imagination": 72.2222,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 72.2222,
          "uncertainty": 83.33330000000001,
          "uneasy": 44.4444,
          "discomfortEnd": 4,
          "discomfortStart": 4,
          "tags": "#LOW-END-PRIORITY,#EMOTIONAL-CALL,#HIGH-EXCITEMENT,#INTEREST",
          "newTagsString": "",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 8,
          "reference": "Seven",
          "extension": "500",
          "creation": "2023-02-01T00:00:00",
          "aggression": 76,
          "arousal": 7,
          "atmosphere": 8,
          "clStress": 9,
          "concentration": 9,
          "discomfort": 8,
          "excitement": 7,
          "hesitation": 6,
          "imagination": 4,
          "joy": 3,
          "mentalEffort": 2,
          "sad": 6,
          "stress": 7,
          "uncertainty": 8,
          "uneasy": 9,
          "discomfortEnd": 0,
          "discomfortStart": 7,
          "tags": "56",
          "newTagsString": "4",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 77,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:44:57.5021752",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 76,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:44:52.9115395",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 75,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:44:48.7402333",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 74,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:44:44.3581395",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 73,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:44:40.3297332",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 72,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:44:35.8630946",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 71,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:44:31.8074906",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 70,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:44:27.5868056",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 69,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:44:23.6207217",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 68,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:44:19.4913455",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 67,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:44:15.5237253",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 66,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:44:10.8312455",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 65,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:44:01.6608323",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 64,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:36:34.2618195",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 63,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:36:30.0884918",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 62,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:36:25.9179564",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 61,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:36:21.7291767",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 60,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:36:17.3791945",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 59,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:36:12.660685",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 58,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:36:06.8496951",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 57,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:36:01.8832131",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 56,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:35:57.264741",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 55,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:35:52.5924518",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 54,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:35:47.4174677",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 53,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:35:42.8429572",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 52,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:35:38.4788203",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 51,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:35:33.7462286",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 50,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:35:29.7108123",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 49,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:35:25.3771238",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 48,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:35:21.333028",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 47,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:35:17.1263082",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 46,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:35:12.5455054",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 45,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:35:08.2217707",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 44,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:35:04.00886",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 43,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:34:59.9087414",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 42,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:34:55.7468108",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 41,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:34:51.6098871",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 40,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:34:47.4902126",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 39,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:34:43.4861054",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 38,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:34:39.4064693",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 37,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:34:35.3574012",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 36,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-31T08:34:28.0776915",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 2,
          "discomfortStart": 3,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 35,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-30T10:03:49.4888991",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 0,
          "discomfortStart": 0,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 34,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-30T10:03:45.5331687",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 0,
          "discomfortStart": 0,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 33,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-30T10:03:41.7456332",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 0,
          "discomfortStart": 0,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 32,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-30T10:03:37.8054298",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 0,
          "discomfortStart": 0,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 31,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-30T10:03:34.0226804",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 0,
          "discomfortStart": 0,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 30,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-30T10:03:30.10966",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 0,
          "discomfortStart": 0,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 29,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-30T10:03:26.3283041",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 0,
          "discomfortStart": 0,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 28,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-30T10:03:22.4433731",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 0,
          "discomfortStart": 0,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 27,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-30T10:03:18.6497352",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 0,
          "discomfortStart": 0,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 26,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-30T10:03:14.6569899",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 0,
          "discomfortStart": 0,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 25,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-30T10:03:10.8410102",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 0,
          "discomfortStart": 0,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 24,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-30T10:03:06.9294937",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 0,
          "discomfortStart": 0,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 23,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-30T10:03:03.1299061",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 0,
          "discomfortStart": 0,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 22,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-30T10:02:59.2111566",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 0,
          "discomfortStart": 0,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 21,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-30T10:02:55.3897933",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 0,
          "discomfortStart": 0,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 20,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-30T10:02:51.5045947",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 0,
          "discomfortStart": 0,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 19,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-30T10:02:47.705584",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 0,
          "discomfortStart": 0,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 18,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-30T10:02:43.7126943",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 0,
          "discomfortStart": 0,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 17,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-30T10:02:39.9354346",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 0,
          "discomfortStart": 0,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 16,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-30T10:02:36.0283707",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 0,
          "discomfortStart": 0,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 15,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-30T10:02:32.1506354",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 0,
          "discomfortStart": 0,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 14,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-30T10:02:26.0165362",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 0,
          "discomfortStart": 0,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 13,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-30T10:00:39.3674767",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 2,
          "concentration": 63.6364,
          "discomfort": 0,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 0,
          "discomfortStart": 0,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 1,
          "reference": "00100001004_20221226_0951490075",
          "extension": "200",
          "creation": "2023-01-29T08:10:44.7398017",
          "aggression": 4.545500000000004,
          "arousal": 31.818200000000004,
          "atmosphere": 31.818200000000004,
          "clStress": 40,
          "concentration": 63.6364,
          "discomfort": 98,
          "excitement": 68.1818,
          "hesitation": 50,
          "imagination": 68.1818,
          "joy": 0,
          "mentalEffort": 0,
          "sad": 0,
          "stress": 95.45455,
          "uncertainty": 45.4545,
          "uneasy": 50,
          "discomfortEnd": 0,
          "discomfortStart": 0,
          "tags": "#MEDIUM-PRIORITY,#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "newTagsString": "#HIGH-END-PRIORITY2,#HIGH-PRIORITY2",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 11,
          "reference": "Fifty",
          "extension": "600",
          "creation": "2023-01-15T00:00:00",
          "aggression": 34,
          "arousal": 88,
          "atmosphere": 77,
          "clStress": 66,
          "concentration": 55,
          "discomfort": 44,
          "excitement": 6,
          "hesitation": 77,
          "imagination": 8,
          "joy": 9,
          "mentalEffort": 7,
          "sad": 6,
          "stress": 5,
          "uncertainty": 6,
          "uneasy": 7,
          "discomfortEnd": 7,
          "discomfortStart": 6,
          "tags": "5",
          "newTagsString": "5",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 7,
          "reference": "Six",
          "extension": "500",
          "creation": "2023-01-06T00:00:00",
          "aggression": 66,
          "arousal": 77,
          "atmosphere": 88,
          "clStress": 99,
          "concentration": 676,
          "discomfort": 545,
          "excitement": 33,
          "hesitation": 22,
          "imagination": 545,
          "joy": 66,
          "mentalEffort": 7788,
          "sad": 9,
          "stress": 99,
          "uncertainty": 66,
          "uneasy": 45,
          "discomfortEnd": 6,
          "discomfortStart": 78,
          "tags": "tag3",
          "newTagsString": "66",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 10,
          "reference": "Ten1",
          "extension": "500",
          "creation": "2023-01-05T00:00:00",
          "aggression": 7,
          "arousal": 7,
          "atmosphere": 6,
          "clStress": 5,
          "concentration": 7,
          "discomfort": 8,
          "excitement": 9,
          "hesitation": 8,
          "imagination": 7,
          "joy": 6,
          "mentalEffort": 5,
          "sad": 4,
          "stress": 3,
          "uncertainty": 56,
          "uneasy": 7,
          "discomfortEnd": 8,
          "discomfortStart": 7,
          "tags": "6",
          "newTagsString": "5",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 12,
          "reference": "FOURTY",
          "extension": "500",
          "creation": "2023-01-02T00:00:00",
          "aggression": 77,
          "arousal": 65,
          "atmosphere": 77,
          "clStress": 88,
          "concentration": 77,
          "discomfort": 66,
          "excitement": 55,
          "hesitation": 44,
          "imagination": 33,
          "joy": 22,
          "mentalEffort": 22,
          "sad": 33,
          "stress": 44,
          "uncertainty": 23,
          "uneasy": 66,
          "discomfortEnd": 88,
          "discomfortStart": 77,
          "tags": "66",
          "newTagsString": "5544",
          "startCall": "2023-02-21T19:39:54",
          "finishCall": "2023-02-21T19:39:54",
          "callSource": "known",
          "callDestination": "unknown",
          "callDuration": 5
      },
      {
          "id": 3,
          "reference": "Second",
          "extension": "200",
          "creation": "2023-01-02T00:00:00",
          "aggression": 4,
          "arousal": 5,
          "atmosphere": 6,
          "clStress": 7,
          "concentration": 8,
          "discomfort": 9,
          "excitement": 9,
          "hesitation": 99,
          "imagination": 77,
          "joy": 77,
          "mentalEffort": 66,
          "sad": 66,
          "stress": 666,
          "uncertainty": 66,
          "uneasy": 55,
          "discomfortEnd": 5,
          "discomfortStart": 7,
          "tags": "9",
          "newTagsString": "9",
          "startCall": "2023-02-21T19:39:54",
          "finishCall": "2023-02-21T19:39:54",
          "callSource": "ali",
          "callDestination": "hnc",
          "callDuration": 2
      },
      {
          "id": 4,
          "reference": "Thrid Demo Ref",
          "extension": "200",
          "creation": "2023-01-02T00:00:00",
          "aggression": 5,
          "arousal": 8,
          "atmosphere": 0,
          "clStress": 6,
          "concentration": 4,
          "discomfort": 8,
          "excitement": 9,
          "hesitation": 0,
          "imagination": 6,
          "joy": 5,
          "mentalEffort": 8,
          "sad": 7,
          "stress": 8,
          "uncertainty": 5,
          "uneasy": 77,
          "discomfortEnd": 77,
          "discomfortStart": 55,
          "tags": "tag2",
          "newTagsString": "23",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 5,
          "reference": "Fourth Dmeo",
          "extension": "300",
          "creation": "2023-01-02T00:00:00",
          "aggression": 34,
          "arousal": 77,
          "atmosphere": 55,
          "clStress": 44,
          "concentration": 88,
          "discomfort": 667,
          "excitement": 44,
          "hesitation": 67,
          "imagination": 88,
          "joy": 55,
          "mentalEffort": 44,
          "sad": 6,
          "stress": 555,
          "uncertainty": 78,
          "uneasy": 5,
          "discomfortEnd": 47,
          "discomfortStart": 8,
          "tags": "9",
          "newTagsString": "66",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 6,
          "reference": "Five",
          "extension": "500",
          "creation": "2023-01-02T00:00:00",
          "aggression": 22,
          "arousal": 66,
          "atmosphere": 55,
          "clStress": 44,
          "concentration": 677,
          "discomfort": 88,
          "excitement": 77,
          "hesitation": 66,
          "imagination": 55,
          "joy": 44,
          "mentalEffort": 33,
          "sad": 77,
          "stress": 88,
          "uncertainty": 4,
          "uneasy": 5,
          "discomfortEnd": 6,
          "discomfortStart": 7,
          "tags": "8",
          "newTagsString": "9",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      },
      {
          "id": 2,
          "reference": "First",
          "extension": "200",
          "creation": "2023-01-01T00:00:00",
          "aggression": 2,
          "arousal": 2,
          "atmosphere": 2,
          "clStress": 3,
          "concentration": 5,
          "discomfort": 5,
          "excitement": 5,
          "hesitation": 5,
          "imagination": 5,
          "joy": 5,
          "mentalEffort": 5,
          "sad": 5,
          "stress": 5,
          "uncertainty": 5,
          "uneasy": 5,
          "discomfortEnd": 5,
          "discomfortStart": 5,
          "tags": "5",
          "newTagsString": "5",
          "startCall": "2023-02-21T19:39:54",
          "finishCall": "2023-02-21T19:39:54",
          "callSource": "abc",
          "callDestination": "xyz",
          "callDuration": 1.6
      },
      {
          "id": 9,
          "reference": "Ten",
          "extension": "500",
          "creation": "2023-01-01T00:00:00",
          "aggression": 87,
          "arousal": 6,
          "atmosphere": 5,
          "clStress": 4,
          "concentration": 6,
          "discomfort": 7,
          "excitement": 89,
          "hesitation": 7,
          "imagination": 9,
          "joy": 8,
          "mentalEffort": 9,
          "sad": 7,
          "stress": 6,
          "uncertainty": 5,
          "uneasy": 4,
          "discomfortEnd": 3,
          "discomfortStart": 5,
          "tags": "6",
          "newTagsString": "7",
          "startCall": null,
          "finishCall": null,
          "callSource": null,
          "callDestination": null,
          "callDuration": null
      }
  ]
}