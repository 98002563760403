import { Routes, Route, NavLink } from "react-router-dom";
import { useEffect } from "react";
import "./App.css";
import Filters from "./Filters/Filters";
import Diagrams from "./Diagrams/Diagrams";
import RepresentativesReport from "./RepresentativesReport/RepresentativesReport";
import "./Base.css";
import "./fonts/pe-icon-7-stroke.min.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  async function fetchAnalytics() {
    const response = await fetch(
      //`https://localhost:7110/api/Profile/GetProfilesAll`,
      `https://localhost:7110/api/Profile/GetProfilesAll`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: ",,,",
          dt: "2023-03-09 2023-03-09",
        }),
      }
    );
    console.log(response, "response");
    const data = await response.json();
    console.log(data, "data");
  }

  useEffect(() => {
    // fetchAnalytics()
  }, []);

  return (
    <div className="app-main__inner">
      <NavLink className="nav-link" to="/">
        Filters
      </NavLink>
      <NavLink className="nav-link" to="diagrams">
        Diagrams
      </NavLink>
      <NavLink className="nav-link" to="RepresentativesReport">
        Representatives Report
      </NavLink>
      <Routes>
        <Route path="/" element={<Filters />} />
        <Route path="diagrams" element={<Diagrams />} />
        <Route path="RepresentativesReport" element={< RepresentativesReport />} />
      </Routes>
    </div>
  );
}

export default App;
