import React, { useEffect, useRef, useState } from "react";
import { recordObject } from "../libs/record";
import axios from 'axios';
import Moment from 'moment';
import Chart from "chart.js/auto";
import { color } from "chart.js/helpers";

import { DateRangePicker } from "rsuite";
var barChartData1 = {};
var barChartData2 = {};
var barChartData4 = {};
var configPie ={};

let startRange = new Date();
let endRange = new Date();
let barChartGrapTwo ='';
let barChartGrapOne ='';
let myPie = '';
let barChartGrapThree ='';
let barChartGrapFour ='';
function Diagrams() {
const [records, setRecords] = useState([]);
const [recordsLen, setRecordsLength] = useState(0);


const [ aggressiveCallsRatio, setAggressiveCallsRatio] = useState(0);
const [ averageToneCallPriority, setAverageToneCallPriority] = useState(0);
const [ callTooShort, setCallTooShort] = useState(0);
const [ totolProcessedCalls, setTotolProcessedCalls] = useState(0);
const [ goodOutcomeNumber,  setGoodOutcomeNumber] = useState(0);
const [ goodOutcomePercent, setGoodOutcomePercent] = useState(0);
const [ averageCallDuration, setAverageCallDuration] = useState('00:00:00');
const [ averageCallPriority, setAverageCallPriority] = useState(0);
const [ averageStresLevelAgent, setAverageStresLevelAgent] = useState(0);
const [ averageStresLevelClient, setAverageStresLevelClient] = useState(0);

const [ highPriorityCallsRatio, setHighPriorityCallsRatio] = useState(0);

const [averageEnergyLevelClient, setAverageEnergyLevelClient] = useState(0);
const [averageEnergyLevelAgent, setAverageEnergyLevelAgent] = useState(0);

const [avAggressionLevelClient, setAvAggressionLevelClient] = useState(0);
const [avAggressionLevelAgent, setAvAggressionLevelAgent] = useState(0);


const [avJoyLevelClient, setAvJoyLevelClient] = useState(0);
const [avJoyLevelAgent, setAvJoyLevelAgent] = useState(0);

const [averageDissatisfationClient, setAverageDissatisfationClient] = useState(0);
const [averageCSSClients, setAverageCSSClients] = useState(0);
const [averageFi10Clients, setAverageFi10Clients] = useState(0);

const [totalRecords, setTotalRecords] = useState(0);
const [totalRecordsNew, setTotalRecordsNew] = useState(0);

const [averageAnticipationAgents, setAverageAnticipationAgents ] = useState(0);
const [goodEngagementCalls, setGoodEngagementCalls] = useState(0);
const chartColors = {
  red: "#dc3545",
  orange: "#fd7e14",
  yellow: "#ffc107",
  green: "#28a745",
  darkGreen: "#28a700",
  blue: "#007bff",
  purple: "#6f42c1",
  grey: "#6c757d",
  aqua: "#00FFFF",
  lightGreen: "#14e684",
};

  function charts(data) {
     var randomScalingFactor = function () {
      return Math.round(Math.random() * 100);
    };
      
     barChartData1 = {
      labels: [""],
      datasets: [
        //{
        //  label: "Agent Priority",
        //  backgroundColor: color(chartColors.darkGreen).rgbString(),
        //  borderColor: chartColors.darkGreen,
        //  borderWidth: 1,
        //  data: [data.agentPriority],
        //},
        //{
        //  label: "Average Call Priority",
        //  backgroundColor: color(chartColors.green).rgbString(),
        //  borderColor: chartColors.green,
        //  borderWidth: 1,
        //  data: [data.averageCallPriority],
        //},
        //{
        //  label: "Call Type Distribution",
        //  backgroundColor: color(chartColors.orange).rgbString(),
        //  borderColor: chartColors.orange,
        //  borderWidth: 1,
        //  data: [data.callTypeDistribution],
        //},
        //{
        //  label: "High Priority Calls Ratio",
        //  backgroundColor: color(chartColors.red).rgbString(),
        //  borderColor: chartColors.red,
        //  borderWidth: 1,
        //  data: [data.highPriorityCallsRatio],
        //},
        //{
        //    label: "Max Call Priority",
        //    backgroundColor: color(chartColors.red).rgbString(),
        //    borderColor: chartColors.red,
        //    borderWidth: 1,
        //    data: [data.maxCallPriority],
        //},
        //{
        //    label: "Max Tone Priority",
        //    backgroundColor: color(chartColors.red).rgbString(),
        //    borderColor: chartColors.red,
        //    borderWidth: 1,
        //    data: [data.maxTonePriority],
        //},
        //{
        //    label: "Solve Problems Ability",
        //    backgroundColor: color(chartColors.red).rgbString(),
        //    borderColor: chartColors.red,
        //    borderWidth: 1,
        //    data: [data.solveProblemsAbility],
        //},
        {
          label: "Max>Final (Low)",
          backgroundColor: color(chartColors.green).rgbString(),
          borderColor: chartColors.green,
          borderWidth: 1,
          data: [data.maxFinalCallPriorityMaxGreaterThanFinalLow],
        },
        {
          label: "Max=Final (Low)",
          backgroundColor: color(chartColors.lightGreen).rgbString(),
          borderColor: chartColors.lightGreen,
          borderWidth: 1,
          data: [data.maxFinalCallPriorityMaxEqualsToFinalLow],
        },
        {
          label: "Max>Final (High)",
          backgroundColor: color(chartColors.orange).rgbString(),
          borderColor: chartColors.orange,
          borderWidth: 1,
          data: [data.maxFinalCallPriorityMaxGreaterThanFinalHigh],
        },
        {
          label: "Max=Final (High)",
          backgroundColor: color(chartColors.red).rgbString(),
          borderColor: chartColors.red,
          borderWidth: 1,
          data: [data.maxFinalCallPriorityMaxEqualsToFinalHigh],
        }
      ],
    };
  
    barChartData2 = {
      labels: [""],
      datasets: [
        {
          label: "Low",
          backgroundColor: color(chartColors.green).rgbString(),
          borderColor: chartColors.green,
          borderWidth: 1,
          data: [data.lowEmotionCallPriority],
        },
        {
          label: "Mid",
          backgroundColor: color(chartColors.orange).rgbString(),
          borderColor: chartColors.orange,
          borderWidth: 1,
          data: [data.midEmotionCallPriority],
        },
        {
          label: "High",
          backgroundColor: color(chartColors.red).rgbString(),
          borderColor: chartColors.red,
          borderWidth: 1,
          data: [data.maxEmotionCallPriority],
        }
      ],
    };
    
    var barChartData3 = {
      labels: [""],
      datasets: [
        {
            label: "Low",
            backgroundColor: color(chartColors.green).rgbString(),
            borderColor: chartColors.green,
            borderWidth: 1,
            data: [data.lowToneCallPriority],
          },
          {
            label: "Mid",
            backgroundColor: color(chartColors.orange).rgbString(),
            borderColor: chartColors.orange,
            borderWidth: 1,
            data: [data.midToneCallPriority],
          },
          {
            label: "High",
            backgroundColor: color(chartColors.red).rgbString(),
            borderColor: chartColors.red,
            borderWidth: 1,
            data: [data.maxToneCallPriority],
          }
      ],
    };
  
    barChartData4 = {
      labels: ["Calls Per Hour"],
      datasets: data.callsPerHourList.map((item, index) => ({
        label: item.countsName,
        backgroundColor: chartColors[`color${index}`],
        borderColor: chartColors[`color${index}`],
        borderWidth: 1,
        data: [item.counts],
      })),
    };
  
    configPie = {
      type: "pie",
      data: {
        datasets: [
          {
            data: data.callTypeDistributionList.map((item) => item.counts),
            backgroundColor: [
              chartColors.red,
              chartColors.green,
              chartColors.blue,
              chartColors.orange,
              chartColors.purple,
            ],
            label: "Dataset 1",
          },
        ],
        labels: data.callTypeDistributionList.map((item) => item.countsName),
      },
      options: {
        responsive: true,
        aspectRatio: 2.4 / 1,
        plugins: {
          legend: {
            position: "right",
          },
        },
      },
    };
    if (document.getElementById("bars-chart-1")) {
      var ctx1 = document.getElementById("bars-chart-1").getContext("2d");
      barChartGrapOne = new Chart(ctx1, {
        type: "bar",
        data: barChartData1,
        options: {
          plugins: {
            legend: {
              position: "right",
            },
          },
  
          title: {
            display: true,
            text: "Chart.js Bar Chart - Stacked",
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
          responsive: true,
          aspectRatio: 3 / 1,
          barPercentage: 0.75,
          borderWidth: 0,
          borderSkipped: true,
          scales: {
            y: {
                min: 0,
                max: 100,
              },
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
        },
      });
    }
    
    if (document.getElementById("bars-chart-2")) {
      var ctx2 = document.getElementById("bars-chart-2").getContext("2d");
      barChartGrapTwo = new Chart(ctx2, {
        type: "bar",
        data: barChartData2,
        options: {
          plugins: {
            legend: {
              position: "right",
            },
          },
          title: {
            display: true,
            text: "Chart.js Bar Chart - Stacked",
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
          responsive: true,
          aspectRatio: 1.5 / 1,
          barPercentage: 0.75,
          borderWidth: 0,
          borderSkipped: true,
          
          scales: {
            y: {
                min: 0,
                max: 100,
              },
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true
              },
            ],
          },
        },
      });
    }
    if (document.getElementById("bars-chart-3")) {
      var ctx3 = document.getElementById("bars-chart-3").getContext("2d");
      barChartGrapThree = new Chart(ctx3, {
        type: "bar",
        data: barChartData3,
        options: {
          plugins: {
            legend: {
              position: "right",
            },
          },
          title: {
            display: true,
            text: "Chart.js Bar Chart - Stacked",
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
          responsive: true,
          aspectRatio: 1.5 / 1,
          barPercentage: 0.75,
          borderWidth: 0,
          borderSkipped: true,
          scales: {
            y: {
                min: 0,
                max: 100,
              },
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
        },
      });
    }
    if (document.getElementById("bars-chart-4")) {
      var ctx1 = document.getElementById("bars-chart-4").getContext("2d");
      barChartGrapFour = new Chart(ctx1, {
        type: "bar",
        data: barChartData4,
        options: {
          plugins: {
            legend: {
              position: "right",
            },
          },
  
          title: {
            display: true,
            text: "Chart.js Bar Chart - Stacked",
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
          responsive: true,
          aspectRatio: 3 / 1,
          barPercentage: 0.75,
          borderWidth: 0,
          borderSkipped: true,
          scales: {
            y: {
                min: 0,
                max: 100,
              },
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
        },
      });
    }
    
    if (document.getElementById("chart-pie")) {
      var ctxPie = document.getElementById("chart-pie").getContext("2d");
       myPie = new Chart(ctxPie, configPie);
    }
  }
  
  const dateRangeRef = useRef(null);
 
  const handleFilterDate = (e) => {
    // const selectedValue = e.target.value;
    console.log(e);
    if (!e) return;
    let months = [
      "",
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let pad = (n) => ("0" + n).slice(-2);
    let p = String(e[0]).split(" ");
    let b = String(e[1]).split(" ");
  
    const startDate = new Date(`${p[3]}-${pad(months.indexOf(p[1]))}-${p[2]}`);
    const endDate = new Date(`${b[3]}-${pad(months.indexOf(b[1]))}-${b[2]}`);
    const startDateTime = new Date(
      `${p[3]}-${pad(months.indexOf(p[1]))}-${p[2]}`
    )?.getTime();
    const endDateTime = new Date(
      `${b[3]}-${pad(months.indexOf(b[1]))}-${b[2]}`
    )?.getTime();
        
   
     startRange = Moment(startDate).format("Y-M-D");
     endRange = Moment(endDate).format("Y-M-D");    
    const config = {
        method: 'post',
        url: 'https://localhost:7110/api/Profile/GetProfilesAll?name=,,,&dt='+startRange+'%20'+endRange,
        headers: { 
            "Content-Type": "application/json",
        }
      };
      
      axios(config)
      .then(function (response) {
        
        //barChartGrapFour.data = response.data.response.callsPerHourList;
        barChartGrapFour.data = {
          labels: ["Calls Per Hour"],
          datasets: response.data.response.callsPerHourList.map((item, index) => ({
            label: item.countsName,
            backgroundColor: chartColors[`color${index}`],
            borderColor: chartColors[`color${index}`],
            borderWidth: 1,
            data: [item.counts],
          })),
        };
        barChartGrapFour.update(barChartData4);
        
        if (barChartGrapThree !== null && barChartGrapThree !== undefined && barChartGrapThree.data !== undefined ) {
          barChartGrapThree.data.datasets[0].data[0] = response.data.response.lowToneCallPriority;
        barChartGrapThree.data.datasets[1].data[0] = response.data.response.midToneCallPriority;
        barChartGrapThree.data.datasets[2].data[0] = response.data.response.maxToneCallPriority;
        barChartGrapThree.update();

        }
        barChartGrapTwo.data.datasets[0].data[0] = response.data.response.lowEmotionCallPriority;
        barChartGrapTwo.data.datasets[1].data[0] = response.data.response.midEmotionCallPriority;
        barChartGrapTwo.data.datasets[2].data[0] = response.data.response.maxEmotionCallPriority;
        barChartGrapTwo.update();

        barChartGrapOne.data.datasets[0].data[0] = response.data.response.maxFinalCallPriorityMaxGreaterThanFinalLow;
        barChartGrapOne.data.datasets[1].data[0] = response.data.response.maxFinalCallPriorityMaxEqualsToFinalLow;
        barChartGrapOne.data.datasets[2].data[0] = response.data.response.maxFinalCallPriorityMaxGreaterThanFinalHigh;
        barChartGrapOne.data.datasets[3].data[0] = response.data.response.maxFinalCallPriorityMaxEqualsToFinalHigh;
        barChartGrapOne.update();


        myPie.data = {
          datasets: [
            {
              data: response.data.response.callTypeDistributionList.map((item) => item.counts),
              backgroundColor: [
                chartColors.red,
                chartColors.green,
                chartColors.blue,
                chartColors.orange,
                chartColors.purple,
              ],
              label: "Dataset 1",
            },
          ],
          labels: response.data.response.callTypeDistributionList.map((item) => item.countsName),
        };
        myPie.update();

        setAggressiveCallsRatio(response.data.response.aggressiveCallsRatio);
        setAverageToneCallPriority(response.data.response.averageToneCallPriority);
        setCallTooShort(response.data.response.callTooShort);
        setTotolProcessedCalls(response.data.response.totolProcessedCalls);
        setGoodOutcomeNumber(response.data.response.goodOutcomeNumber);
        setGoodOutcomePercent(response.data.response.goodOutcomePercent);
        setAverageCallDuration(response.data.response.averageCallDuration);
        setAverageCallPriority(response.data.response.averageCallPriority);
        setHighPriorityCallsRatio(response.data.response.highPriorityCallsRatio);
        setAverageStresLevelAgent(response.data.response.averageStresLevelAgent);
        setAverageStresLevelClient(response.data.response.averageStresLevelClient);

        setAverageEnergyLevelClient(response.data.response.averageEnergyLevelClient);
        setAverageEnergyLevelAgent(response.data.response.averageEnergyLevelAgent);

        setAvAggressionLevelClient(response.data.response.avAggressionLevelClient);
        setAvAggressionLevelAgent(response.data.response.avAggressionLevelAgent);
        
        setAvJoyLevelClient(response.data.response.avJoyLevelClient);
        setAvJoyLevelAgent(response.data.response.avJoyLevelAgent);

        setAverageDissatisfationClient(response.data.response.averageDissatisfationClient);
        setAverageCSSClients(response.data.response.averageCSSClients);
        setAverageFi10Clients(response.data.response.averageFi10Clients);
        setTotalRecords(response.data.response.totalRecords)
        setTotalRecordsNew(response.data.response.totalRecordsNew)
        setAverageAnticipationAgents(response.data.response.averageAnticipationAgents);
        setGoodEngagementCalls(response.data.response.goodEngagementCalls);

        if(response.data.response.profiles != null) {
            setRecordsLength(response.data.response.profiles.length)
             setRecords(response.data.response.profiles);
             
         } else {
             setRecords([]);
             
         }
         
      }).catch(function (error) {
        console.log(error);
      });  




    setRecords(() =>
      recordObject.record.filter((rec) => {
        const creationDate = new Date(rec?.creation?.split("T")[0]);
        const creationDateTime = new Date(
          rec?.creation?.split("T")[0]
        ).getTime();
        // console.log(
        //   creationDate,
        //   "creationDate",
        //   creationDateTime,
        //   "creationDateTime"
        // );
        
        // console.log(
        //   creationDateTime >= startDateTime && creationDate <= endDateTime
        // );
        return (
          creationDateTime >= startDateTime && creationDateTime <= endDateTime
        );
      })
    );
  };
  function getProfilesAll(){
       
    const startRange = Moment().format("Y-M-D");
    const endRange = Moment().format("Y-M-D");
      const config = {
        method: 'post',
        url: 'https://localhost:7110/api/Profile/GetProfilesAll?name=,,,&dt='+startRange+'%20'+endRange,
        headers: { 
            "Content-Type": "application/json",
        }
      };
      
    
    axios(config)
    .then(function (response) {
        //setRecordsLength(response.data.response.profiles.length) 
        setRecords(response.data.response.profiles);
        charts(response.data.response);
        setRecordsLength(response.data.response.profiles !== undefined ? response.data.response.profiles.length : 0);
        setAggressiveCallsRatio(response.data.response.aggressiveCallsRatio);
        setAverageToneCallPriority(response.data.response.averageToneCallPriority);
        setCallTooShort(response.data.response.callTooShort);
        setTotolProcessedCalls(response.data.response.totolProcessedCalls);
        setGoodOutcomeNumber(response.data.response.goodOutcomeNumber);
        setGoodOutcomePercent(response.data.response.goodOutcomePercent);
        setAverageCallDuration(response.data.response.averageCallDuration);
        setAverageCallPriority(response.data.response.averageCallPriority);
        setHighPriorityCallsRatio(response.data.response.highPriorityCallsRatio);
        setAverageStresLevelAgent(response.data.response.averageStresLevelAgent);
        setAverageStresLevelClient(response.data.response.averageStresLevelClient);

        setAverageEnergyLevelClient(response.data.response.averageEnergyLevelClient);
        setAverageEnergyLevelAgent(response.data.response.averageEnergyLevelAgent);

        setAvAggressionLevelClient(response.data.response.avAggressionLevelClient);
        setAvAggressionLevelAgent(response.data.response.avAggressionLevelAgent);

        setAvJoyLevelClient(response.data.response.avJoyLevelClient);
        setAvJoyLevelAgent(response.data.response.avJoyLevelAgent);
        setAverageDissatisfationClient(response.data.response.averageDissatisfationClient);
        setAverageCSSClients(response.data.response.averageCSSClients);
        setAverageFi10Clients(response.data.response.averageFi10Clients);

        setAverageAnticipationAgents(response.data.response.averageAnticipationAgents);
        setGoodEngagementCalls(response.data.response.goodEngagementCalls);

    })
    .catch(function (error) {
      console.log(error);
    });


}
  useEffect(() => {
   getProfilesAll();
  }, []);

  function createCSV(array) {
    delete array.profiles;
    delete array.defaultTags;
    delete array.allTags;
  
    let keys = Object.keys(array);
    let result = '';
    result += keys.join(',') + '\n';
  
    keys.forEach((key) => {
      if (key !== 'profiles' && key !== 'defaultTags' && key !== 'allTags') {
        let value = array[key];
        if (typeof value === 'object' && value !== null) {
          value = JSON.stringify(value);
        }
        result += `${value},`;
      }
    });
  
    return result;
  }

 
  function downloadCSV() {
    
   let csv = 'data:text/csv;charset=utf-8,' + createCSV(records); //Creates CSV File Format
   let excel = encodeURI(csv); //Links to CSV 
  
   let link = document.createElement('a');
    link.setAttribute('href', excel); //Links to CSV File 
    link.setAttribute('download', 'reports-'+startRange+'-'+endRange+'.csv'); //Filename that CSV is saved as
    link.click();
  }

  const currentdateTime = Moment();
  
  return (
    <div className="col-lg-12">
      <div className="analytics-top-bar row justify-content-between align-items-center mb-2">
        <div className="col-auto">
          <div className="d-flex">
            <button className="me-2 pt-0 pb-0 d-flex align-items-center btn-transition btn btn-outline-dark">Dark</button>
            <div className="col-auto">
                  <label htmlFor="staticSearch" className="visually-hidden">
                    Date
                  </label>
                  {/* <input type="text" className="form-control" id="daterange" name="daterange" placeholder="From - To (Date Range)" />
                  <input type="hidden" id="hideDates" /> */}
                  <DateRangePicker
                    appearance="default"
                    format="yyyy-MM-dd hh:mm aa"
                    onChange={handleFilterDate}
                    ref={dateRangeRef}
                    defaultValue={[new Date(currentdateTime), new Date(currentdateTime)]}
                  />
                </div>
          </div>
          
        </div>
        <div className="col-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="col-auto">
              <p style={{margin: '0'}}>totalRecords:{totalRecords} ({totalRecordsNew} New)</p>
            </div>
            <div className="col-auto">
              <div className="position-relative form-check">
                <label className="form-label form-check-label">
                  <input type="checkbox" className="form-check-input" />
                    Check me out
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="col-auto">
          <div className="d-flex align-items-center" style={{gap: '10px'}}>
            <div className="col-auto">
              <div className="position-relative form-check">
                <label className="form-label form-check-label">
                  <input type="checkbox" className="form-check-input" />
                    Check me out
                </label>
              </div>
            </div>
            <div className="col-auto input-container">
              <label htmlFor="staticSearch" className="visually-hidden">
                Seaching
              </label>
                  <input
                    type="text"
                    className="form-control"
                    id="staticSearch"
                    placeholder="Search Call Source/Destination"
                  />
                <span className="clear-icon">&times;</span>
            </div>
            
          </div>
        </div>
      </div>
      <div className="cards row gx-3 mb-2">
        {/*<div className="col-3">
          <div className="card border custom-br">
            <div className="card-body">
              <h4 className="text-title mb-2 text-center">Total Profile</h4>
              <p className="value mb-0 text-center">{recordsLen}</p>
            </div>
          </div>
        </div>*/}
        <div className="col-2-25">
          <div className="card border custom-br">
            <div className="card-body">
              <h4 className="text-title mb-2 text-center">Total Calls Processed</h4>
              <p className="value mb-0 text-center">{ totolProcessedCalls}</p>
            </div>
          </div>
        </div>
        <div className="col-2-25">
          <div className="card border custom-br">
            <div className="card-body">
              <h4 className="text-title mb-2 text-center">Good Outcome</h4>
              <p className="value mb-0 text-center">{goodOutcomeNumber+' ('+`${Math.floor(goodOutcomePercent).toFixed(1)}%`+')'}</p>
            </div>
          </div>
        </div>
        <div className="col-2-25">
          <div className="card border custom-br">
            <div className="card-body">
              <h4 className="text-title mb-2 text-center">Average call duration</h4>
              <p className="value mb-0 text-center">{averageCallDuration}</p>
            </div>
          </div>
        </div>
        <div className="col-2-25">
          <div className="card border custom-br">
            <div className="card-body">
              <h4 className="text-title mb-2 text-center">Average call priority</h4>
              <p className="value mb-0 text-center">{Math.floor(averageCallPriority).toFixed(1)}%</p>
            </div>
          </div>
        </div>
        <div className="col-2-25">
          <div className="card border custom-br">
            <div className="card-body">
              <h4 className="text-title mb-2 text-center">High Priority Calls Ratio</h4>
              <p className="value mb-0 text-center">{Math.floor(highPriorityCallsRatio).toFixed(1)}%</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row gx-1 ">
        <div className="col-9 d-flex flex-column">
          <div className="row gx-1 mb-1" style={{ flex: "0 0 50%" }}>
            <div className="col-5 d-flex flex-column ">
              <div className="card mb-1 custom-br">
                <div className="card-body ">
                  <h4 className="text-title mb-2 float-left col-12">
                    <span className="float-left">Aggressive calls ratio</span>
                    <div className="float-right">{ aggressiveCallsRatio.toFixed(1)+'%'}</div>
                  </h4>
                  <h4 className="text-title mb-2 float-left col-12">
                    <span className="float-left">Average Tone call priority</span>
                    <div className="float-right">{  averageToneCallPriority.toFixed(1) }</div>
                  </h4>
                  <h4 className="text-title mb-0 float-left col-12">
                    <span className="float-left">Call too short</span>
                    <div className="float-right">{  callTooShort.toFixed(1)+'%' }</div>
                  </h4>
                </div>
              </div>
              <div className="card flex-fill custom-br">
                <div className="card-body">
                  <h5 className="card-title">Call per hours</h5>
                  <canvas
                    id="bars-chart-4"
                    height="372"
                    className="chartjs-render-monitor"
                    style={{
                      display: "block",
                      width: "744px",
                      height: "372px",
                    }}
                    width="744"
                  ></canvas>
                </div>
              </div>
            </div>
            <div className="col-7">
              <div className="main-card card custom-br h-100">
                <div className="card-body">
                  <h5 className="card-title">Max Emotion Call Priority & final Call Priority</h5>
                  <canvas
                    id="bars-chart-1"
                    height="372"
                    className="chartjs-render-monitor"
                    style={{
                      display: "block",
                      width: "744px",
                      height: "372px",
                    }}
                    width="744"
                  ></canvas>
                </div>
              </div>
            </div>
          </div>
          <div className="row gx-1 flex-fill">
            <div className="col-4">
              <div className="card h-100 custom-br">
                <div className="card-body d-flex flex-column">
                  <h5 className="card-title">Call Type Distribution</h5>
                  <div className="chart-pie__container">
                    <canvas
                      id="chart-pie"
                      width="744"
                      height="372"
                      style={{
                        display: "block",
                        width: "744px",
                        height: "372px",
                      }}
                      className="chartjs-render-monitor"
                    ></canvas>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-8">
              <div className="card h-100 custom-br">
                <div className="card-body">
                  <div className="row h-100">
                    <div className="col-6 d-flex flex-column">
                      <h5 className="card-title">MAX EMOTION CALL PRIORITY</h5>
                      <div className="half-size-chart flex-fill">
                        <canvas
                          id="bars-chart-2"
                          className="chartjs-render-monitor"
                          style={{
                            display: "block",
                            width: "100%",
                            height: "100%",
                          }}
                        ></canvas>
                      </div>
                    </div>
                    <div className="col-6 d-flex flex-column">
                      <h5 className="card-title">MAX TONE PRIORITY</h5>
                      <div className="half-size-chart flex-fill">
                        <canvas
                          id="bars-chart-3"
                          className="chartjs-render-monitor"
                          style={{
                            display: "block",
                            width: "100%",
                            height: "100%",
                          }}
                        ></canvas>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="row gy-1">
            <div className="col-12">
              <div className="card border custom-br">
                <div className="card-body">
                  <h4 className="text-title mb-2">
                    <span>Average Stress Level (clients):</span>{averageStresLevelClient.toFixed(1)}
                  </h4>
                  <h4 className="text-title mb-0">
                    <span>Average Stress Level (Agents):</span>{averageStresLevelAgent.toFixed(1)}
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card border custom-br">
                <div className="card-body">
                  <h4 className="text-title mb-2">
                    <span>Average energy Level (clients):</span>{averageEnergyLevelClient.toFixed(1)}
                  </h4>
                  <h4 className="text-title mb-0">
                    <span>Average energy Level (Agents):</span>{averageEnergyLevelAgent.toFixed(1)}
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card border custom-br">
                <div className="card-body">
                  <h4 className="text-title mb-2">
                        <span>Ava aggression level (clients):</span>{avAggressionLevelClient.toFixed(1)}
                  </h4>
                  <h4 className="text-title mb-0">
                        <span>Ava agression level (Agents):</span>{avAggressionLevelAgent.toFixed(1)}  
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card border custom-br">
                <div className="card-body">
                <h4 className="text-title mb-2">
                    <span>Average Joy Level (clients):</span>{avJoyLevelClient.toFixed(1)}
                  </h4>
                  <h4 className="text-title mb-0">
                    <span>Average Joy Level (Agents):</span>{avJoyLevelAgent.toFixed(1)}
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="card border custom-br">
                <div className="card-body">
                  <h4 className="text-title mb-2">
                    <span>Average Dissatisfaction (Clients):</span>{averageDissatisfationClient.toFixed(1)}
                  </h4>
                  <h4 className="text-title mb-2">
                    <span>Average Fi10 (Clients):</span>{averageFi10Clients.toFixed(1)}
                  </h4>
                  <h4 className="text-title mb-3">
                    <span>Average CSS (Clients):</span>{averageCSSClients.toFixed(1)}
                  </h4>
                  <h4 className="text-title mb-2">
                    <span>Average Anticipation (Agents)</span>{averageAnticipationAgents.toFixed(1)}
                  </h4>
                  <h4 className="text-title mb-0">
                    <span>Good Engagement Calls</span>{goodEngagementCalls.toFixed(1)}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="analytics-footer row justify-content-between align-items-center mt-2">
        <div className="col-6">
        <button className="me-2 btn-icon btn btn-dark" style={{width: '65%'}}>
          <i className="pe-7s-upload btn-icon-wrapper"></i>Upload
        </button>
        </div>
        <div className="col-2">
          <div className="position-relative form-check">
            <label className="form-label form-check-label">
              <input type="checkbox" className="form-check-input" />
                Check me out
            </label>
          </div>
        </div>
        <div className="col-4">
          <div className="d-flex">
            <button onClick={()=>downloadCSV()} className="me-2 btn btn-info flex-fill">Export Excel</button>
            <button className="btn btn-info flex-fill">Info</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Diagrams;
