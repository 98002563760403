import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import Moment from "moment";
import { recordObject } from "../libs/record";
import { DateRangePicker } from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import "rsuite/dist/rsuite.min.css";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import Table from "../RepresentativesReport/RepresentativesReport";
let tagValue = "";
let defaultTagValue = "";
let extensionValue = 0;
let duration = 0;

function Filters() {
  //const [records, setRecords] = useState(recordObject.record);
  const [records, setRecords] = useState([]);
  const [originalRecords, setOriginalRecords] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [defaultTags, setDefaultTags] = useState([]);
  const [isFilterDefaultActive, setFilterDefaultActive] = useState(-1);

  const [tagsSelectedValue, setTagsSelectedValue] = useState(null);

  const currentdateTime = Moment();

  const [distinctExtensions, setDistinctExtensions] = useState([]);

  const dateRangeRef = useRef(null);

  // Please take records from this POST api call https://localhost:7110/api/Profile/GetProfilesAll?name=,,,&dt=2023-02-01%202023-03-12
  // axios
  const handleFilterTags = (e) => {
    let selectedValue = "";

    if (e != null) {
      selectedValue = e.value;
      tagValue = selectedValue;
      defaultTagValue = tagValue;
      setFilterDefaultActive(-1);
      setTagsSelectedValue({ label: tagValue, value: tagValue });
    }
    if (selectedValue === "") {
      tagValue = "";
      defaultTagValue = "";
      setTagsSelectedValue(null);
      setFilterDefaultActive(-1);
      return;
    }

    multFilter();
  };

  const handleFilterDefaultTags = (e) => {
    setFilterDefaultActive(e.target.attributes.getNamedItem("data-key").value);
    const selectedValue = e.target.attributes.getNamedItem("data-value").value;
    defaultTagValue = selectedValue;

    tagValue = defaultTagValue;
    setTagsSelectedValue({ label: tagValue, value: tagValue });
    multFilter();
  };

  const handlesFilterExtension = (e) => {
    if (e != null) {
      const selectedValue = e.value;
      extensionValue = selectedValue;
    } else {
      extensionValue = 0;
    }
    multFilter();
  };
  const handlesFilterDuration = (e) => {
    const selectedValue = e.target.value;
    duration = selectedValue;
    console.log(selectedValue, "selectedValue");
    console.log(1.6 >= 5);
    multFilter();
  };

  const handleFilterDate = (e) => {
    // const selectedValue = e.target.value;
    console.log(e);
    if (!e) return;
    let months = [
      ,
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    let pad = (n) => ("0" + n).slice(-2);
    let p = String(e[0]).split(" ");
    let b = String(e[1]).split(" ");

    const startDate = new Date(`${p[3]}-${pad(months.indexOf(p[1]))}-${p[2]}`);
    const endDate = new Date(`${b[3]}-${pad(months.indexOf(b[1]))}-${b[2]}`);
    const startDateTime = new Date(
      `${p[3]}-${pad(months.indexOf(p[1]))}-${p[2]}`
    )?.getTime();
    const endDateTime = new Date(
      `${b[3]}-${pad(months.indexOf(b[1]))}-${b[2]}`
    )?.getTime();

    setRecords(() =>
      recordObject.record.filter((rec) => {
        const creationDate = new Date(rec?.creation?.split("T")[0]);
        const creationDateTime = new Date(
          rec?.creation?.split("T")[0]
        ).getTime();
        console.log(
          creationDate,
          "creationDate",
          creationDateTime,
          "creationDateTime"
        );
        console.log(startDate, "startData", startDateTime, "startDateTime");
        console.log(endDate, "endDate", endDateTime, "endDateTime");

        const startRange = Moment(startDate).format("Y-M-D");
        const endRange = Moment(endDate).format("Y-M-D");

        const config = {
          method: "post",
          url:
            "https://localhost:7110/api/Profile/GetProfilesAll?name=,,,&dt=" +
            startRange +
            "%20" +
            endRange,
          headers: {
            "Content-Type": "application/json",
          },
        };

        axios(config)
          .then(function (response) {
            if (response.data.response.profiles != null) {
              setRecords(response.data.response.profiles);
              setOriginalRecords(response.data.response.profiles);
            }

            if (response.data.response.allTags != null)
              setAllTags(response.data.response.allTags);

            if (response.data.response.defaultTags != null)
              setDefaultTags(response.data.response.defaultTags);

            if (response.data.response.distinctExtensions != null)
              setDistinctExtensions(response.data.response.distinctExtensions);
          })
          .catch(function (error) {
            console.log(error);
          });

        console.log(
          creationDateTime >= startDateTime && creationDate <= endDateTime
        );
        return (
          creationDateTime >= startDateTime && creationDateTime <= endDateTime
        );
      })
    );
  };

  const multFilter = () => {
    let pushData = [];

    pushData = originalRecords;

    if (tagValue != "" || defaultTagValue != "") {
      //setRecords(() => {
      //    pushData.filter((record) => {
      //        return record.tags.includes(tagValue) === true;
      //    })
      //    }
      //);
      pushData = pushData.filter((record) => {
        return record.tags.includes(tagValue) === true;
      });
    }
    if (extensionValue > 0) {
      //setRecords(() =>
      //pushData.filter((record) => record.extension == extensionValue)
      //);
      pushData = pushData.filter(
        (record) => record.extension == extensionValue
      );
      console.log(pushData);
    }
    if (duration > 0) {
      pushData = pushData.filter((record) => {
        if (record?.callDuration) {
          console.log(
            record?.callDuration,
            "record?.callDuration",
            +duration,
            "selectedValue"
          );
          return +record?.callDuration >= +duration;
        }
      });
    }
    setRecords(pushData);
  };

  function getProfilesAll() {
    const startRange = Moment().format("Y-M-D");
    const endRange = Moment().format("Y-M-D");
    const config = {
      method: "post",
      //url: 'https://localhost:7110/api/Profile/GetProfilesAll?name=,,,&dt='+startRange+'%20'+endRange,
      url:
        "https://localhost:7110/api/Profile/GetProfilesAll?name=,,,&dt=" +
        startRange +
        "%20" +
        endRange,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.response.profiles != null) {
          setRecords(response.data.response.profiles);
          setOriginalRecords(response.data.response.profiles);
        }

        if (response.data.response.allTags != null)
          setAllTags(response.data.response.allTags);

        if (response.data.response.defaultTags != null)
          setDefaultTags(response.data.response.defaultTags);

        if (response.data.response.distinctExtensions != null)
          setDistinctExtensions(response.data.response.distinctExtensions);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const tagsOptions = allTags.map((o) => ({
    label: o,
    value: o,
  }));
  const distinctExtensionsOption = distinctExtensions.map((o) => ({
    label: o,
    value: o,
  }));
  console.log(distinctExtensions);
  useEffect(() => {
    getProfilesAll();
  }, []);

  return (
    <>
      <div className="main-card mb-3 card">
        <div className="card-body">
          <div>
            <div className="divider"></div>
            <form className="g-3">
              <div
                className="row"
                style={{ marginBottom: "10px", marginTop: "10px" }}
              ></div>
              <div className="row">
                <div className="col-auto input-container mb-2">
                  <label htmlFor="tags" className="visually-hidden">
                    Tags
                  </label>

                  <Select
                    className="my-select basic-single"
                    classNamePrefix="select"
                    value={tagsSelectedValue}
                    isClearable={true}
                    name="tags"
                    id="tags"
                    onChange={(e) => handleFilterTags(e)}
                    options={tagsOptions}
                    style={{ width: `${8 * tagsOptions.length + 100}px` }}
                    placeholder="- Select Tags -"
                  />

                  {/*<select
                    onChange={handleFilterTags}
                    name="tags"
                    id="tags"
                    className="form-control my-select"
                    value={defaultTagValue}
                  >
                    <option value="">- Select Tags -</option>
                    {allTags.map((allTag,index) => (
                    <option key={index} value={allTag}>{allTag}</option>
                    ))}
                  </select>*/}
                  {defaultTags.map((defaultTag, index) => (
                    <div
                      className={
                        isFilterDefaultActive == index
                          ? "me-2 pt-0 pb-0 d-flex align-items-center btn-transition btn btn-outline-dark mt-2 active"
                          : "me-2 pt-0 pb-0 d-flex align-items-center btn-transition btn btn-outline-dark mt-2"
                      }
                      key={index}
                      data-key={index}
                      onClick={(e) => handleFilterDefaultTags(e)}
                      data-value={defaultTag}
                    >
                      {defaultTag}
                    </div>
                  ))}
                </div>

                <div className="col-auto input-container">
                  <label htmlFor="extensions" className="visually-hidden">
                    Extensions
                  </label>
                  <Select
                    className="my-select basic-single"
                    classNamePrefix="select"
                    isClearable={true}
                    name="extensions"
                    id="extensions"
                    onChange={(e) => handlesFilterExtension(e)}
                    style={{ width: `${8 * tagsOptions.length + 100}px` }}
                    options={distinctExtensionsOption}
                    placeholder="- Select Extensions -"
                  />
                  {/*<select
                    onChange={handlesFilterExtension}
                    name="extensions"
                    id="extensions"
                    className="form-control my-select"
                  >
                    <option value="">- Select Extensions -</option>
                    {distinctExtensions.map((distinctExtension,index) => (
                    <option key={index} value={distinctExtension}>{distinctExtension}</option>
                    ))}
                  </select>*/}
                  {/*<span className="clear-icon">&times;</span>*/}
                </div>

                <div className="col-auto input-container">
                  <label htmlFor="durationSearch" className="visually-hidden">
                    Duration
                  </label>
                  <input
                    onChange={handlesFilterDuration}
                    type="search"
                    className="form-control"
                    id="durationSearch"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="Search by duration"
                  />
                  <span className="clear-icon">&times;</span>
                </div>

                <div className="col-auto input-container">
                  <label htmlFor="staticSearch" className="visually-hidden">
                    Seaching
                  </label>
                  <input
                    type="search"
                    className="form-control"
                    id="staticSearch"
                    placeholder="Search Call Source/Destination"
                  />
                  <span className="clear-icon">&times;</span>
                </div>
                <div className="col-auto">
                  <label htmlFor="staticSearch" className="visually-hidden">
                    Date
                  </label>
                  {/* <input type="text" className="form-control" id="daterange" name="daterange" placeholder="From - To (Date Range)" />
                  <input type="hidden" id="hideDates" /> */}
                  <DateRangePicker
                    appearance="default"
                    format="yyyy-MM-dd hh:mm aa"
                    onChange={handleFilterDate}
                    ref={dateRangeRef}
                    defaultValue={[
                      new Date(currentdateTime),
                      new Date(currentdateTime),
                    ]}
                  />
                </div>
                <div className="col-auto">
                  <button
                    type="button"
                    id="searchBtn"
                    className="btn btn-primary mb-3"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h2 className="card-title">
                Profiles List =&gt; Its a dummy data enter in db.
              </h2>
            </div>
            <div
              className="card-body profileCardBody"
              style={{ width: "100%", overflowX: "scroll" }}
            >
              <table
                id="userProfiles"
                className="table Profiles table-hover table-striped table-bordered dataTable dtr-inline"
                cellSpacing="0"
                width="100%"
              >
                <thead>
                  <tr style={{ height: "120px" }}>
                    <th style={{ width: "80px" }}>
                      {" "}
                      <span> Actions</span>
                    </th>
                    <th style={{ width: "300px" }}>
                      <span>Reference</span>
                    </th>
                    <th style={{ width: "100px" }}>
                      <span>Extension</span>
                    </th>
                    <th style={{ width: "110px" }}>
                      <span>Creation</span>
                    </th>
                    <th className="vertical">
                      <span>Aggression</span>
                    </th>
                    <th className="vertical">
                      <span>Arousal</span>
                    </th>
                    <th className="vertical">
                      <span>Atmosphere</span>
                    </th>
                    <th className="vertical">
                      <span>ClStress</span>
                    </th>
                    <th className="vertical">
                      <span>Concentration</span>
                    </th>
                    <th className="vertical">
                      <span>Discomfort</span>
                    </th>
                    <th className="vertical">
                      <span>Excitement</span>
                    </th>
                    <th className="vertical">
                      <span>Hesitation</span>
                    </th>
                    <th className="vertical">
                      <span>Imagination</span>
                    </th>
                    <th className="vertical">
                      <span>Joy</span>
                    </th>
                    <th className="vertical">
                      <span>MentalEffort</span>
                    </th>
                    <th className="vertical">
                      <span>Sad</span>
                    </th>
                    <th className="vertical">
                      <span>Stress</span>
                    </th>
                    <th className="vertical">
                      <span>Uncertainty</span>
                    </th>
                    <th className="vertical">
                      <span>Uneasy</span>
                    </th>
                    <th className="vertical">
                      <span>DiscomfortEnd</span>
                    </th>
                    <th className="vertical">
                      <span>DiscomfortStart</span>
                    </th>
                    <th className="vertical" style={{ width: "300px" }}>
                      <span>Tags</span>
                    </th>
                    <th className="vertical" style={{ width: "300px" }}>
                      <span>NewTagsString</span>
                    </th>
                    <th className="vertical">
                      <span>Call Destination</span>
                    </th>
                    <th className="vertical">
                      <span>Call Source</span>
                    </th>
                    <th>
                      <span>Call Start</span>
                    </th>
                    <th>
                      <span>Call End</span>
                    </th>
                    <th>
                      <span>Call Duration</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {records &&
                    records.map((record, index) => (
                      <tr key={record.creation + index}>
                        <td>
                          <button
                            type="button"
                            className="btn btn-outline-info waves-effect waves-light editbtn-product"
                            title="Details"
                            data-product-id={record?.id}
                          >
                            <FontAwesomeIcon icon={faUser} />
                          </button>
                        </td>
                        <td>
                          <span>{record?.reference}</span>
                        </td>
                        <td>
                          <span>{record?.extension}</span>
                        </td>
                        <td>
                          <span>{record?.creation?.split("T")[0]}</span>
                        </td>
                        <td>
                          <span>{record?.aggression}</span>
                        </td>
                        <td>
                          <span>{record?.arousal}</span>
                        </td>
                        <td>
                          <span>{record?.atmosphere}</span>
                        </td>
                        <td>
                          <span>{record?.clStress}</span>
                        </td>
                        <td>
                          <span>{record?.concentration}</span>
                        </td>
                        <td>
                          <span>{record?.discomfort}</span>
                        </td>
                        <td>
                          <span>{record?.excitement}</span>
                        </td>
                        <td>
                          <span>{record?.hesitation}</span>
                        </td>
                        <td>
                          <span>{record?.imagination}</span>
                        </td>
                        <td>
                          <span>{record?.joy}</span>
                        </td>
                        <td>
                          <span>{record?.mentalEffort}</span>
                        </td>
                        <td>
                          <span>{record?.sad}</span>
                        </td>
                        <td>
                          <span>{record?.stress}</span>
                        </td>
                        <td>
                          <span>{record?.uncertainty}</span>
                        </td>
                        <td>
                          <span>{record?.uneasy}</span>
                        </td>
                        <td>
                          <span>{record?.discomfortEnd}</span>
                        </td>
                        <td>
                          <span>{record?.discomfortStart}</span>
                        </td>
                        <td>
                          <span>{record?.tags}</span>
                        </td>
                        <td>
                          <span>{record?.newTagsString}</span>
                        </td>
                        <td>
                          <span>{record?.startCall}</span>
                        </td>
                        <td>
                          <span>{record?.finishCall}</span>
                        </td>
                        <td>
                          <span>{record?.callSource}</span>
                        </td>
                        <td>
                          <span>{record?.callDestination}</span>
                        </td>
                        <td>
                          <span>{record?.callDuration}</span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Filters;
